import React from "react"
import { Box } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import Wave from "react-wavify"
import { Link } from "gatsby-theme-material-ui"

import InstagramIcon from "@material-ui/icons/Instagram"
import FacebookIcon from "@material-ui/icons/Facebook"

function Footer() {
  return (
    <div>
      <Wave
        className="me-wave"
        fill="#242325"
        paused={false}
        options={{
          height: 90,
          amplitude: 25,
          speed: 0.08,
          points: 4,
        }}
      />
      <footer className="me-footer me-bg-quase-preto">
        <Box p={2}>
          <Grid container>
            <Grid item lg={6} sm={12} md={6}>
              <img
                className="me-logo"
                alt="Logomarca Grafiame"
                src="/img/logomarca.png"
              ></img>
              <Box mt={2}>
                <a
                  className="tag-a-redes"
                  alt="logo Instagram"
                  target="_blank"
                  href="https://instagram.com/grafiame"
                >
                  <InstagramIcon htmlColor="#fff" />
                </a>
                <a
                  className="tag-a-redes"
                  alt="logo Facebook"
                  target="_blank"
                  href="https://facebook.com/grafiame"
                >
                  <FacebookIcon htmlColor="#fff" />
                </a>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              direction="row"
              container
              // justify="center"
            >
              <Box display="flex" flexDirection="column" mr={5}>
                <h3 className="me-h3">A empresa</h3>
                <Link className="link" to="/por-que-grafiame">
                  Por que a Grafiame?
                </Link>
                <Link className="link" to="/solucoes">
                  Soluções
                </Link>
                <Link className="link" to="/sobre">
                  Sobre nós
                </Link>
              </Box>
              <Box display="flex" flexDirection="column" mr={5}>
                <h3 className="me-h3">Recursos</h3>
                <Link className="link" to="https://medium.com/grafiame">
                  Blog(ue)
                </Link>
                <Link className="link" onClick={() => window.open("https://integradaeducativa.grafiame.com.br/", "_self")}>
                  Entrar
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={7}>
            <hr className="linha" />
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <p className="me-end">
              Feito com <del>um teclado</del> amor e muito café.
            </p>
          </Box>
        </Box>
      </footer>
    </div>
  )
}
export default Footer
